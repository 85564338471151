<template>
  <Swiper
    v-if="items"
    class="mt-14 !px-4 md:!hidden"
    :space-between="24"
    :slides-per-view="1.1"
    :slides-per-group="1"
    :breakpoints="{
      '640': {
        slidesPerView: 2.1,
        slidesPerGroup: 1,
      },
    }"
  >
    <SwiperSlide
      v-for="(item, index) in items"
      :key="item.id"
      v-slot="{ isActive }"
    >
      <MediaItem
        :url="item.url"
        :image="item.image?.sm ?? item?.sm"
        :image-srcset="`
          ${item.image?.md.url ?? item.md.url} ${item.image?.md.width ?? item.md.width}w,
          ${item.image?.sm.url ?? item.sm.url} ${item.image?.sm.width ?? item.sm.width}w
        `"
        image-sizes="
          (min-width: 1920px) 624px,
          (min-width: 768px) 30vw,
          80vw
        "
        :title="item.title"
        :company="item.company"
        :tags="item.project_tags"
        :logo="item.project_logo"
        :class="`
          transition-transform duration-300
          ${isActive ? 'translate-y-0' : 'translate-y-[8%]'}
        `"
        :title-below="false"
        ratio="aspect-[12/13]"
        :disable-lazy-loading="index === 0"
      />
    </SwiperSlide>
  </Swiper>

  <div
    v-if="items"
    class="hidden md:grid md:grid-cols-3 md:gap-6 md:-mt-[19vw] md:mx-auto md:px-4 md:max-w-[120rem] [@media(min-width:1920px)]:px-0"
    :class="{
      'md:pb-[17vw]': type === 'images',
      'md:pb-[12vw]': type === 'projects',
    }"
  >
    <MediaItem
      v-for="(item, index) in items"
      :key="item.id"
      :url="item.url"
      :image="item.image?.sm ?? item?.sm"
      :image-srcset="`
        ${item.image?.md.url ?? item.md.url} ${item.image?.md.width ?? item.md.width}w,
        ${item.image?.sm.url ?? item.sm.url} ${item.image?.sm.width ?? item.sm.width}w
      `"
      image-sizes="
        (min-width: 1920px) 624px,
        (min-width: 768px) 30vw,
        80vw
      "
      :title="item.title"
      :company="item.company"
      :tags="item.project_tags"
      :logo="item.award_logo"
      :class="mediaItemClass[type][index] || ''"
      :title-below="false"
      ratio="aspect-[32/35]"
      data-gsap-target="mediaItem"
    />
  </div>
</template>

<script setup lang="ts">
import type { Image } from '~/statamic/types/image';
import type { Project } from '~/statamic/types/project';

defineProps<{
  type: string;
  items?: Image[] | Project[];
}>();

const mediaItemClass: { [key: string]: string[] } = {
  projects: [
    'md:z-[2] md:translate-y-[17vw]',
    '',
    'md:z-[2] md:translate-y-[5vw]',
  ],

  images: [
    'md:z-[2] md:translate-y-[12vw]',
    'md:translate-y-[17vw]',
    'md:z-[2] md:translate-y-[5vw]',
  ],
};
</script>
