<template>
  <div ref="blockRef">
    <div class="relative z-[1] flex flex-col pointer-events-none">
      <h1
        v-if="block.title"
        v-html="formatTitle(block.title)"
        :class="`
          -ml-[2%] leading-none whitespace-nowrap [&_>span:last-child]:relative [&_>span:last-child]:-top-[0.20em] md:order-2
          ${block.title_color.value}
          ${!block.text ? 'md:mt-[5.8125rem]' : 'md:mt-4'}
        `"
        data-gsap-target="title"
      />

      <div
        v-if="block.text"
        class="prose mt-6 mx-auto px-4 text-purple-dark text-center font-light max-w-[33rem] md:order-1 md:m-0 md:text-lg md:text-left md:translate-x-[calc(100vw-100%)] lg:translate-x-[calc(100vw-120%)] xl:translate-x-[calc(100vw-150%)] 2xl:translate-x-[calc(100vw-180%)]"
        v-html="block.text"
        data-gsap-target="text"
      />

      <Buttons
        v-if="block.buttons?.length"
        :buttons="block.buttons"
        :class="`
          mt-8 mx-auto pointer-events-auto md:order-3 md:absolute md:z-[1] md:left-[18%] md:-translate-x-full md:m-0
          ${!block.text ? 'md:top-[55%]' : 'md:top-[52%]'}
        `"
        data-gsap-target="buttons"
      />
    </div>

    <BlocksHeroMediaItems
      v-if="block.type_of_content && (block.images || block.projects)"
      :type="block.type_of_content?.value"
      :items="block.type_of_content?.value === 'images' ? block.images : block.projects"
    />
  </div>
</template>

<script setup lang="ts">
import type { Hero } from '~/statamic/types/block';

const props = defineProps<{
  block: Hero;
}>();

let ctx: gsap.Context | null;
const blockRef = ref();

onMounted(() => {
  ctx = useGsap.context((self) => {
    if (typeof self.selector !== 'function') return;

    const title = self.selector('[data-gsap-target="title"]');
    const text = self.selector('[data-gsap-target="text"]');
    const buttons = self.selector('[data-gsap-target="buttons"]');
    const words = self.selector('[data-gsap-target="word"]');
    const mediaItems = self.selector('[data-gsap-target="mediaItem"]');

    const timeline = useGsap.timeline({
      scrollTrigger: {
        start: 'top bottom',
        end: 'bottom top',
        trigger: blockRef.value,
      },
    });

    const scrollTimeline = useGsap.timeline({
      scrollTrigger: {
        scrub: true,
        start: 'top-=100 top',
        trigger: blockRef.value,
      },
    });

    if (title?.length) {
      timeline.from(title, {
        autoAlpha: 0,
      });

      scrollTimeline.to(title, {
        yPercent: props.block.images || props.block.projects ? -25 : 75,
      }, '0');
    }

    if (text?.length) {
      timeline.from(text, {
        autoAlpha: 0,
      });
    }

    if (buttons?.length) {
      timeline.from(buttons, {
        delay: 2,
        autoAlpha: 0,
      }, '-=2');
    }

    if (mediaItems?.length) {
      timeline.from(mediaItems, {
        stagger: 0.5,
        autoAlpha: 0,
      }, text?.length ? '-=3' : '0');

      scrollTimeline.to(mediaItems, {
        y: -150,
      }, '0');
    }

    if (words?.length) {
      const wordsTimeline = useGsap.timeline();

      words.forEach((word: HTMLSpanElement, index: number) => {
        wordsTimeline
          .to(word, {
            scale: 1.1,
            autoAlpha: 1,
            duration: 0.25,
          })
          .to(word, {
            scale: 1,
            autoAlpha: isLast(index, words.length) ? 1 : 0,
            duration: 0.25,
            delay: 0.25,
          });
      });
    }
  }, blockRef.value);
});

onUnmounted(() => {
  ctx?.revert();
});
</script>
